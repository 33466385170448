<template>
  <div >
        <div class="header">
             <ul class="left_menu">
                <div class="item"> 
                    <li class="active a">平台管理员</li>
                     <li><router-link to="/index5/gonggao55">系统公告</router-link> </li>
                </div>
                 <div  class="item">  
                    <li class="a" ><router-link to="/index5/xiaoxi55" style="display:flex;    align-items: center">  <i class=" el-icon-message-solid" style="color:#0034FF;width:25px;height:25px;border-radius:100%;background: #f5f5f5;text-align:center;line-height:25px;margin-right:5px"></i> <p style="width:15px;height:15px;background:red;text-align:center;font-size:12px;line-height:15px;border-radius:100%;color:#fff;margin-left:-10px;margin-top:-3px" v-if='result !==0' >{{result}}</p> 消息通知</router-link> </li>
              <li style="cursor:pointer;" @click="dialogFormVisible = true">技术专员<i class="el-icon-caret-bottom"></i>  </li>
                </div>    
                                        <el-dialog title="个人信息" style="text-align:center" :visible.sync="dialogFormVisible">
                        <el-form :model="info">
                            <el-form-item label="昵称" :label-width="formLabelWidth">
                            <el-input v-model="info.name" autocomplete="off">  <el-button slot="append" @click="a11()">修改</el-button></el-input>
                         
                            </el-form-item>
                            <el-form-item label="登录账号" :label-width="formLabelWidth">
                             <el-input v-model="info.phone" autocomplete="off"  :disabled="true"></el-input>
                            </el-form-item>
                             <el-form-item label="登录密码" :label-width="formLabelWidth">
                             <el-input v-model="password3" placeholder="输入旧密码"  autocomplete="off">  <el-button slot="append"  @click="a22()">修改</el-button></el-input>
                            </el-form-item>
                            <el-form-item label="新密码" v-show="password2" :label-width="formLabelWidth">
                             <el-input v-model="password" autocomplete="off">  <el-button slot="append"  @click="a33()">确认修改</el-button> </el-input>
                            </el-form-item>
                        </el-form>
                        <div slot="footer" class="dialog-footer">
                            <el-button type="primary" @click="tuichu" style="width:100%">退出登录</el-button>
                       
                        </div>
                        </el-dialog>  
             </ul>
        </div>
        <div class="content">
             <div>
                 <ul class="content_ul">
                     <!-- <li style="background:#9351CC;color:#fff;height:90px;border-radius:10px;margin-right: 15px;">
                         <p style="font-size:14px;margin:12px 0">全新钢琴</p>
                          <p style="font-size:30px;margin: 0">{{list.piano_new_count}}</p>
                     </li>
                     <li style="background:#00ADD6;color:#fff;height:90px;border-radius:10px;margin-right: 15px;">
                         <p style="font-size:14px;margin:12px 0">二手钢琴</p>
                       <p style="font-size:30px;margin: 0">{{list.piano_second_hand_count}}</p>
                     </li>-->
                     
                      <li style="background:#49ADA8;color:#fff;height:90px;border-radius:10px;margin-right: 15px;">
                         <p style="font-size:14px;margin:12px 0">调律师数量</p>
                            <p style="font-size:30px;margin: 0">{{tls_count}}</p>
                     </li>
                    
                     <li style="background:#0093E0;color:#fff;height:90px;border-radius:10px;margin-right: 15px;">
                         <p style="font-size:14px;margin:12px 0">五星订单数量</p>
                              <p style="font-size:30px;margin: 0">{{lists.five_star_count}}</p>
                     </li>
                    
                 </ul>
             </div>
            <div class="content_1">
                 <div class="left" style="padding:10px;background:#fff;margin-right:17px">
                     <p style="font-size: 16px;
                font-family: PingFangSC-Medium, PingFang SC;
                margin:20px 0;
                font-weight: 600;
                color: #000000;
                line-height: 22px;">五星订单统计 </p>
                 <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
                    <el-tab-pane label="今日" name="first" @click="today">
                            <p style="font-size:14px;margin:20px 0">累计总数：<span style="font-size: 24px;margin-right:10px;
                font-family: DINAlternate-Bold, DINAlternate;
                font-weight: bold;
                color: #0034FF;">{{c_list.total}}</span> 今日新增: <span style="font-size: 24px;
                font-family: DINAlternate-Bold, DINAlternate;
                font-weight: bold;
                color: #FF7400;
                line-height: 28px;">{{c_list.today_total}}</span> </p>
         <div id="myChart"  :style="{ height: '240px'}"></div>

                    </el-tab-pane>
                    <el-tab-pane label="近7天" name="second" @click="seven">
                           <p style="font-size:14px;margin:20px 0">累计总数：<span style="font-size: 24px;margin-right:10px;
font-family: DINAlternate-Bold, DINAlternate;
font-weight: bold;
color: #0034FF;">{{c_list.total}}</span> 七天新增: <span style="font-size: 24px;
font-family: DINAlternate-Bold, DINAlternate;
font-weight: bold;
color: #FF7400;
line-height: 28px;">{{c_list.today_total}}</span> </p>
         <div id="myChart1"  :style="{ height: '240px'}"></div>
                    </el-tab-pane>
                    <el-tab-pane label="本月" name="third" @click="thirds">
                          <p style="font-size:14px;margin:20px 0">累计总数：<span style="font-size: 24px;margin-right:10px;
font-family: DINAlternate-Bold, DINAlternate;
font-weight: bold;
color: #0034FF;">{{c_list.total}}</span> 本月新增: <span style="font-size: 24px;
font-family: DINAlternate-Bold, DINAlternate;
font-weight: bold;
color: #FF7400;
line-height: 28px;">{{c_list.today_total}}</span> </p>
         <div id="myChart2"  :style="{ height: '240px'}"></div>

                    </el-tab-pane>
                    <el-tab-pane label="半年" name="fourth" @click="fours">
                           <p style="font-size:14px;margin:20px 0">累计总数：<span style="font-size: 24px;margin-right:10px;
font-family: DINAlternate-Bold, DINAlternate;
font-weight: bold;
color: #0034FF;">{{c_list.total}}</span> 半年新增: <span style="font-size: 24px;
font-family: DINAlternate-Bold, DINAlternate;
font-weight: bold;
color: #FF7400;
line-height: 28px;">{{c_list.today_total}}</span> </p>
         <div id="myChart3"  :style="{ height: '240px'}"></div>
                    </el-tab-pane>
                    <el-tab-pane label="一年" name="five" @click="fives">
                           <p style="font-size:14px;margin:20px 0">累计总数：<span style="font-size: 24px;margin-right:10px;
font-family: DINAlternate-Bold, DINAlternate;
font-weight: bold;
color: #0034FF;">{{c_list.total}}</span>一年新增: <span style="font-size: 24px;
font-family: DINAlternate-Bold, DINAlternate;
font-weight: bold;
color: #FF7400;
line-height: 28px;">{{c_list.today_total}}</span> </p>
         <div id="myChart4"  :style="{ height: '240px'}"></div>
                    </el-tab-pane>
                </el-tabs>

                <div>
                    <p style="font-size: 14px;
font-family: PingFangSC-Regular, PingFang SC;
font-weight: 400;
color: #293460;font-weight:600;margin:20px 0;
line-height: 18px;">全国调律师统计</p>
                     <el-table
                     :border="true"
    :data="tableData"
   :header-cell-style="{background:'#FAFAFA'}"
    style="width: 100%">
    <el-table-column
      label="城市"
      prop="name" 
      width="80">

    </el-table-column>
    <el-table-column
      label="累计调律师人数(人)"
      prop="tls_total" 
      width="130">
    
    </el-table-column>

       <el-table-column
      label="本月新增调律师(人)"
    prop="tls_this_month" 
      
      width="130">
    
    </el-table-column>
       <el-table-column
        prop="five_star_total" 
      label="五星订单总数(人)"
      width="130">
   
    </el-table-column>
    <el-table-column label="更多">
     <template slot-scope="scope">
        <el-button
          size="mini"
          type="danger"
           @click="handleEdit(scope.$index, scope.row)">查看详情</el-button>
     </template>
    </el-table-column>
  </el-table>
                </div>
             </div>

             <div class=" right" style="padding-left:10px;padding-top:10px;padding-right:10px;background:#fff;">
                  <p style="font-size: 16px;
                font-family: PingFangSC-Medium, PingFang SC;
                margin:20px 0;
                font-weight: 600;
                color: #000000;
                line-height: 22px;">平台订单统计 </p>
                 <el-tabs v-model="activeName1" type="card" @tab-click="handleClick1">
                    <el-tab-pane label="今日" name="first1">
                               <p style="font-size:14px;margin:20px 0"> 
                  今日订单数: <span style="font-size: 24px;margin-right:10px;
                font-family: DINAlternate-Bold, DINAlternate;
                font-weight: bold;
                color: #FF7400;
                line-height: 28px;">{{c_list1.today_order}}</span>
                
                </p>
         <div id="myChart0"  :style="{ height: '240px'}"></div>
                    </el-tab-pane>
                    <el-tab-pane label="近7天" name="second1" @click="seven1">
                                    <p style="font-size:14px;margin:20px 0">
                7日订单数: <span style="font-size: 24px;margin-right:10px;
                font-family: DINAlternate-Bold, DINAlternate;
                font-weight: bold;
                color: #FF7400;
                line-height: 28px;">{{c_list1.today_order}}</span>
              
                </p>
         <div id="myChart01"  :style="{ height: '240px'}"></div>

                    </el-tab-pane>
                    <el-tab-pane label="本月" name="third1" @click="thirds1">
                                   <p style="font-size:14px;margin:20px 0">  
                本月订单数: <span style="font-size: 24px;margin-right:10px;
                font-family: DINAlternate-Bold, DINAlternate;
                font-weight: bold;
                color: #FF7400;
                line-height: 28px;">{{c_list1.today_order}}</span>
                
                </p>
         <div id="myChart02"  :style="{ height: '240px'}"></div>
                    </el-tab-pane>
                    <el-tab-pane label="半年" name="fourth1" @click="fours1">
                                           <p style="font-size:14px;margin:20px 0">
                半年订单数: <span style="font-size: 24px;margin-right:10px;
                font-family: DINAlternate-Bold, DINAlternate;
                font-weight: bold;
                color: #FF7400;
                line-height: 28px;">{{c_list1.today_order}}</span>
              
                </p>
         <div id="myChart03"  :style="{ height: '240px'}"></div>
                    </el-tab-pane>
                    <el-tab-pane label="一年" name="five1" @click="fives1">
                        <p style="font-size:14px;margin:20px 0"> 
                一年订单数: <span style="font-size: 24px;
                font-family: DINAlternate-Bold, DINAlternate;margin-right:10px;
                font-weight: bold;
                color: #FF7400;
                line-height: 28px;">{{c_list1.today_order}}</span>
               
                </p>
         <div id="myChart04"  :style="{ height: '240px'}"></div>

                    </el-tab-pane>
                </el-tabs>
                 <div>
                   <p style="font-size: 14px;
font-family: PingFangSC-Regular, PingFang SC;
font-weight: 400;
color: #293460;font-weight:600;margin:20px 0;
line-height: 18px;">全国城市订单数量统计</p>
                     <el-table
                     :border="true"
    :data="tableData1"
   :header-cell-style="{background:'#FAFAFA'}"
    style="width: 100%">
    <el-table-column
      label="城市"
      prop="province" 
      width="80">

    </el-table-column>
    <el-table-column
      label="累计订单量"
      prop="total" 
      width="130">
    
    </el-table-column>

       <el-table-column
      label="本月订单量"
    prop="this_month" 
      
      width="130">
    
    </el-table-column>
       <el-table-column
        prop="today" 
      label="今日新增订单"
      width="130">
   
    </el-table-column>
    <el-table-column label="更多">
     <template slot-scope="scope">
        <el-button
          size="mini"
          type="danger"
           @click="handleEdit1(scope.$index, scope.row)">查看详情</el-button>
     </template>
    </el-table-column>
  </el-table>
                </div>
             </div>
            </div>
            
        </div>
  </div>
</template>

<script>
 import echarts from 'echarts'
export default {
    data(){
        return{
            tls_count:'',
            result:'',
            lists:'',
            dialogFormVisible:false, form: {
          name: '',
          region: '',
          date1: '',
          date2: '',
          delivery: false,
          type: [],
          resource: '',
          desc: ''
        },
        formLabelWidth: '120px',
info:[],

            activeName: 'first',
              activeName1: 'first1',
            list:'',
            c_list:'',
                 tableData: [],
                    tableData1: [],
                 c_list1:'',
                 password:'',
                   password2:false,
                  password3:''
        }
    },
    methods:{
        tuichu(){
            this.dialogFormVisible = false;
            window.localStorage.removeItem('token')
                     this.$router.push({ name: 'login'})
        },
        a11(){
          this.$axios({
                        method: "post",
                        url: "/s/backend/api/userupdate",
                        params: {
                        accessToken : localStorage.getItem('token')
                        },
                        data:this.info,
                        }) .then(res=>{
                            this.dialogFormVisible = false
                              this.$message({
                   message:'修改成功',
                  type: 'success'
                     });
                        })
        .catch(function(){
           
        })  
        },
        a22(){
            this.password2=true
         
              
        },
        a33(){
               if(this.password ==''){
                this.$message({
                   message:'请输入新密码后点击修改',
                  type: 'success'
                     }); 
            }else{
                    this.$axios({
                            method: "post",
                            url: "/s/backend/api/changepass",
                            params: {
                            accessToken : localStorage.getItem('token')
                            },
                            data:{
                        "jobnumber":  localStorage.getItem('jobnumber'),
                        "newpassword": this.password,
                        "oldpassword": this.password3
                        },
                            }) .then(res=>{
                            
                            if(res.data.code == 0){
                                    this.dialogFormVisible = false
                                    this.$message({
                    message:'修改成功',
                    type: 'success'
                        });
                            }
                            })
            .catch(function(){
            
            })  
            }
        },
          handleClick(tab, event) {
             if(tab.name == 'first'){
        	// 触发‘配置管理’事件
        	this.today();
        }else if(tab.name == 'second'){
            this.seven()
            }else if (tab.name == 'third'){
                    this.thirds()
            }else if(tab.name == 'fourth'){
                this.fours()
            }else if(tab.name=='five'){
                this.fives()}
      },
  handleClick1(tab, event) {
             if(tab.name == 'first1'){
        	// 触发‘配置管理’事件
        	this.today1();
        }else if(tab.name == 'second1'){
            this.seven1()
            }else if (tab.name == 'third1'){
                    this.thirds1()
            }else if(tab.name == 'fourth1'){
                this.fours1()
            }else if(tab.name=='five1'){
                this.fives1()}
      },
          handleEdit(index, row) {
          localStorage.setItem("xq",this.tableData[index].id);
         this.$router.push({ name: 'xiangqing123'})
      },
    handleEdit1(index, row) {
          localStorage.setItem("xq",this.tableData1[index].id);
         this.$router.push({ name: 'xiangqing2222'})
      },
        timeForMat (count) {
// 拼接时间
　　let time1 = new Date()
　　time1.setTime(time1.getTime() - (24 * 60 * 60 * 1000))
　　let Y1 = time1.getFullYear()
　　let M1 = ((time1.getMonth() + 1) > 10 ? (time1.getMonth() + 1) : '0' + (time1.getMonth() + 1))
　　let D1 = (time1.getDate() > 10 ? time1.getDate() : '0' + time1.getDate())
　　let timer1 = Y1 + '-' + M1 + '-' + D1 // 当前时间
　　let time2 = new Date()
　　time2.setTime(time2.getTime() - (24 * 60 * 60 * 1000 * count))
　　let Y2 = time2.getFullYear()
　　let M2 = ((time2.getMonth() + 1) > 9 ? (time2.getMonth() + 1) : '0' + (time2.getMonth() + 1))
　　let D2 = (time2.getDate() > 9 ? time2.getDate() : '0' + time2.getDate())
　　// let timer2 = Y2 + '-' + M2 + '-' + D2 // 之前的7天或者30天
　　// return {
　　// // t1: timer1,
　　// // t2: timer2
　　// }
　　return Y2 + '-' + M2 + '-' + D2 ;
},
    
    today(){
        var that  = this
            var date=new Date();
              let timer123 = that.timeForMat(1)
  let timer124 = that.timeForMat(0)
         var dateStart1 = date.getFullYear() + '-' +(date.getMonth() + 1) + '-' + date.getDate()
              that.$axios.get("/s/backend/index/new_tls_statistic", {
        　  　params: { 'accessToken':localStorage.getItem('token'),
        'starttime':timer123,
        'endtime':timer124}
        })

        .then(res=>{
                that.c_list=  res.data.result
            let arrs = []
            let arrs1 = []
           let arrs2 = []
            that.c_list.statistic.forEach(e => {
            arrs.push(e.name)
             arrs1.push(e.today)
            arrs2.push(e.total)
         })
         console.log(arrs1,arrs2)
     
                  let myChartDrawer =  this.$echarts.init(document.getElementById('myChart'))
       
                // 绘制条形图
                var option = {
                    title: {
                        text: 'TOP10城市统计',
                        top: 5,
                        left: 'left'
                    },
                       color:['#4C73FD','#0034FF'],
                    legend: {
                        data: ['今日新增', '累计总数'],
                        top: 30
                    },  tooltip: {
                 trigger: "axis",  
            },
                    // X轴
                    xAxis: {
                    data: arrs,
                    splitLine:{
                    show:false,
                },
                    },
                    // Y轴
                    yAxis: {  splitLine:{
                    show:false,
                },},
                   
                    // 数据
                    series: [
                        {
                            name: '今日新增',
                            type: 'bar',
                            
                            data:arrs1
                        },
                        {
                            name: '累计总数',
                            type: 'bar',  barGap:'-100%',
                            data:arrs2
                        }
                      
                    ]
                };

                myChartDrawer.setOption(option);
           
        })
        .catch(function(){
           
        })
    },
    today1(){
        var that = this
        var t111= that.timeForMat(0)
  var t112 = that.timeForMat(0)
          that.$axios.get("/s/backend/index/order_statistic", {
        　  　params: { 'accessToken':localStorage.getItem('token'),
        'starttime':t111,
        'endtime':t112}
        })

        .then(res=>{
                that.c_list1=  res.data.result
            let arrs = []
            let arrs1 = []
           let arrs2 = []
            that.c_list1.statistic.forEach(e => {
            arrs.push(e.province)
             arrs1.push(e.today)
            arrs2.push(e.total)
         })
      
     
                  let myChartDrawer =  this.$echarts.init(document.getElementById('myChart0'))
       
                // 绘制条形图
                var option = {
                    title: {
                        text: 'TOP10城市统计',
                        top: 5,
                        left: 'left'
                    },
                       color:['#4C73FD','#0034FF'],
                    legend: {
                        data: ['今日新增', '累计总数'],
                        top: 30
                    },  tooltip: {
                 trigger: "axis",  
            },
                    // X轴
                    xAxis: {
                    data: arrs,
                    splitLine:{
                    show:false,
                },
                    },
                    // Y轴
                    yAxis: {  splitLine:{
                    show:false,
                },},
                   
                    // 数据
                    series: [
                        {
                            name: '今日新增',
                            type: 'bar',
                            
                            data:arrs1
                        },
                        {
                            name: '累计总数',
                            type: 'bar',  barGap:'-100%',
                            data:arrs2
                        }
                      
                    ]
                };

                myChartDrawer.setOption(option);
           
        })
        .catch(function(){
           
        })
     
      
    },

 fives(){
        var that  = this
            var date=new Date();
                var t111= that.timeForMat(365)
  var t112 = that.timeForMat(-1)
              that.$axios.get("/s/backend/index/new_tls_statistic", {
        　  　params: { 'accessToken':localStorage.getItem('token'),
        'starttime':t111,
        'endtime':t112}
        })

        .then(res=>{
                that.c_list=  res.data.result
            let arrs = []
            let arrs1 = []
           let arrs2 = []
            that.c_list.statistic.forEach(e => {
            arrs.push(e.name)
             arrs1.push(e.today)
            arrs2.push(e.total)
         })
         console.log(arrs1,arrs2)
     
                  let myChartDrawer =  this.$echarts.init(document.getElementById('myChart4'))
       
                // 绘制条形图
                var option = {
                    title: {
                        text: 'TOP10城市统计',
                        top: 5,
                        left: 'left'
                    },
                       color:['#4C73FD','#0034FF'],
                    legend: {
                        data: ['一年新增', '累计总数'],
                        top: 30
                    },  tooltip: {
                 trigger: "axis",  
            },
                    // X轴
                    xAxis: {
                    data: arrs,
                    splitLine:{
                    show:false,
                },
                    },
                    // Y轴
                    yAxis: {  splitLine:{
                    show:false,
                },},
                   
                    // 数据
                    series: [
                        {
                            name: '一年新增',
                            type: 'bar',
                            
                            data:arrs1
                        },
                        {
                            name: '累计总数',
                            type: 'bar',  barGap:'-100%',
                            data:arrs2
                        }
                      
                    ]
                };

                myChartDrawer.setOption(option);
           
        })
        .catch(function(){
           
        })
    },

 fives1(){
        var that  = this
            var date=new Date();
            var t111= that.timeForMat(365)
  var t112 = that.timeForMat(-1)
              that.$axios.get("/s/backend/index/order_statistic", {
        　  　params: { 'accessToken':localStorage.getItem('token'),
        'starttime':t111,
        'endtime':t112}
        })

        .then(res=>{
                that.c_list1=  res.data.result
            let arrs = []
            let arrs1 = []
           let arrs2 = []
            that.c_list1.statistic.forEach(e => {
            arrs.push(e.province)
             arrs1.push(e.today)
            arrs2.push(e.total)
         })
         console.log(arrs1,arrs2)
     
                  let myChartDrawer =  this.$echarts.init(document.getElementById('myChart04'))
       
                // 绘制条形图
                var option = {
                    title: {
                        text: 'TOP10城市统计',
                        top: 5,
                        left: 'left'
                    },
                       color:['#4C73FD','#0034FF'],
                    legend: {
                        data: ['一年新增', '累计总数'],
                        top: 30
                    },  tooltip: {
                 trigger: "axis",  
            },
                    // X轴
                    xAxis: {
                    data: arrs,
                    splitLine:{
                    show:false,
                },
                    },
                    // Y轴
                    yAxis: {  splitLine:{
                    show:false,
                },},
                   
                    // 数据
                    series: [
                        {
                            name: '一年新增',
                            type: 'bar',
                            
                            data:arrs1
                        },
                        {
                            name: '累计总数',
                            type: 'bar',  barGap:'-100%',
                            data:arrs2
                        }
                      
                    ]
                };

                myChartDrawer.setOption(option);
           
        })
        .catch(function(){
           
        })
    },

 fours(){
        var that  = this
            var date=new Date();
           let timer = that.timeForMat(182)
            var dateStart1 =that.timeForMat(-1)
              that.$axios.get("/s/backend/index/new_tls_statistic", {
        　  　params: { 'accessToken':localStorage.getItem('token'),
        'starttime':timer,
        'endtime':dateStart1}
        })

        .then(res=>{
                that.c_list=  res.data.result
            let arrs = []
            let arrs1 = []
           let arrs2 = []
            that.c_list.statistic.forEach(e => {
            arrs.push(e.name)
             arrs1.push(e.today)
            arrs2.push(e.total)
         })
         console.log(arrs1,arrs2)
     
                  let myChartDrawer =  this.$echarts.init(document.getElementById('myChart3'))
       
                // 绘制条形图
                var option = {
                    title: {
                        text: 'TOP10城市统计',
                        top: 5,
                        left: 'left'
                    },
                       color:['#4C73FD','#0034FF'],
                    legend: {
                        data: ['半年新增', '累计总数'],
                        top: 30
                    },  tooltip: {
                 trigger: "axis",  
            },
                    // X轴
                    xAxis: {
                    data: arrs,
                    splitLine:{
                    show:false,
                },
                    },
                    // Y轴
                    yAxis: {  splitLine:{
                    show:false,
                },},
                   
                    // 数据
                    series: [
                        {
                            name: '半年新增',
                            type: 'bar',
                            
                            data:arrs1
                        },
                        {
                            name: '累计总数',
                            type: 'bar',  barGap:'-100%',
                            data:arrs2
                        }
                      
                    ]
                };

                myChartDrawer.setOption(option);
           
        })
        .catch(function(){
           
        })
    },
fours1(){
        var that  = this
            var date=new Date();
           let timer = that.timeForMat(182)
            var dateStart1 =that.timeForMat(-1)
              that.$axios.get("/s/backend/index/order_statistic", {
        　  　params: { 'accessToken':localStorage.getItem('token'),
        'starttime':timer,
        'endtime':dateStart1}
        })

        .then(res=>{
                that.c_list1=  res.data.result
            let arrs = []
            let arrs1 = []
           let arrs2 = []
            that.c_list1.statistic.forEach(e => {
            arrs.push(e.province)
             arrs1.push(e.today)
            arrs2.push(e.total)
         })
         console.log(arrs1,arrs2)
     
                  let myChartDrawer =  this.$echarts.init(document.getElementById('myChart03'))
       
                // 绘制条形图
                var option = {
                    title: {
                        text: 'TOP10城市统计',
                        top: 5,
                        left: 'left'
                    },
                       color:['#4C73FD','#0034FF'],
                    legend: {
                        data: ['半年新增', '累计总数'],
                        top: 30
                    },  tooltip: {
                 trigger: "axis",  
            },
                    // X轴
                    xAxis: {
                    data: arrs,
                    splitLine:{
                    show:false,
                },
                    },
                    // Y轴
                    yAxis: {  splitLine:{
                    show:false,
                },},
                   
                    // 数据
                    series: [
                        {
                            name: '半年新增',
                            type: 'bar',
                            
                            data:arrs1
                        },
                        {
                            name: '累计总数',
                            type: 'bar',  barGap:'-100%',
                            data:arrs2
                        }
                      
                    ]
                };

                myChartDrawer.setOption(option);
           
        })
        .catch(function(){
           
        })
    },
  thirds(){
        
        var that  = this
   var date=new Date();
  let timer = that.timeForMat(30)
    let timer2 = that.timeForMat(-1)
              that.$axios.get("/s/backend/index/new_tls_statistic", {
        　  　params: { 'accessToken':localStorage.getItem('token'),
        'starttime':timer,
        'endtime':timer2}
        })

        .then(res=>{
                that.c_list=  res.data.result
            let arrs = []
            let arrs1 = []
           let arrs2 = []
            that.c_list.statistic.forEach(e => {
            arrs.push(e.name)
             arrs1.push(e.today)
            arrs2.push(e.total)
         })
         console.log(arrs1,arrs2)
     
                  let myChartDrawer =  this.$echarts.init(document.getElementById('myChart2'))
       
                // 绘制条形图
                var option = {
                    title: {
                        text: 'TOP10城市统计',
                        top: 5,
                        left: 'left'
                    },
                       color:['#4C73FD','#0034FF'],
                    legend: {
                        data: ['今月新增', '累计总数'],
                        top: 30
                    },  tooltip: {
                 trigger: "axis",  
            },
                    // X轴
                    xAxis: {
                    data: arrs,
                    splitLine:{
                    show:false,
                },
                    },
                    // Y轴
                    yAxis: {  splitLine:{
                    show:false,
                },},
                   
                    // 数据
                    series: [
                        {
                            name: '今月新增',
                            type: 'bar',
                            
                            data:arrs1
                        },
                        {
                            name: '累计总数',
                            type: 'bar',  barGap:'-100%',
                            data:arrs2
                        }
                      
                    ]
                };

                myChartDrawer.setOption(option);
           
        })
        .catch(function(){
           
        })
    },

     thirds1(){
        
        var that  = this
   var date=new Date();
    let timer2 = that.timeForMat(30)
        let dateStart1 = that.timeForMat(-1)
              that.$axios.get("/s/backend/index/order_statistic", {
        　  　params: { 'accessToken':localStorage.getItem('token'),
        'starttime':timer2,
        'endtime':dateStart1}
        })

        .then(res=>{
                that.c_list1=  res.data.result
            let arrs = []
            let arrs1 = []
           let arrs2 = []
            that.c_list1.statistic.forEach(e => {
            arrs.push(e.province)
             arrs1.push(e.today)
            arrs2.push(e.total)
         })
         console.log(arrs1,arrs2)
     
                  let myChartDrawer =  this.$echarts.init(document.getElementById('myChart02'))
       
                // 绘制条形图
                var option = {
                    title: {
                        text: 'TOP10城市统计',
                        top: 5,
                        left: 'left'
                    },
                       color:['#4C73FD','#0034FF'],
                    legend: {
                        data: ['今月新增', '累计总数'],
                        top: 30
                    },  tooltip: {
                 trigger: "axis",  
            },
                    // X轴
                    xAxis: {
                    data: arrs,
                    splitLine:{
                    show:false,
                },
                    },
                    // Y轴
                    yAxis: {  splitLine:{
                    show:false,
                },},
                   
                    // 数据
                    series: [
                        {
                            name: '今月新增',
                            type: 'bar',
                            
                            data:arrs1
                        },
                        {
                            name: '累计总数',
                            type: 'bar',  barGap:'-100%',
                            data:arrs2
                        }
                      
                    ]
                };

                myChartDrawer.setOption(option);
           
        })
        .catch(function(){
           
        })
    },
     seven(){
           var that  = this
            var date=new Date();

    let timer2 = that.timeForMat(6)
      let timer1 = that.timeForMat(-1)
              that.$axios.get("/s/backend/index/new_tls_statistic", {
        　  　params: { 'accessToken':localStorage.getItem('token'),
        'starttime':timer2,
        'endtime':timer1}
        })

        .then(res=>{
                that.c_list=  res.data.result
            let arrs = []
            let arrs1 = []
           let arrs2 = []
            that.c_list.statistic.forEach(e => {
            arrs.push(e.name)
             arrs1.push(e.today)
            arrs2.push(e.total)
         })
         console.log(arrs1,arrs2)
     
                  let myChartDrawer =  this.$echarts.init(document.getElementById('myChart1'))
       
                // 绘制条形图
                var option = {
                    title: {
                        text: 'TOP10城市统计',
                        top: 5,
                        left: 'left'
                    },
                       color:['#4C73FD','#0034FF'],
                    legend: {
                        data: ['七日新增', '累计总数'],
                        top: 30
                    },  tooltip: {
                 trigger: "axis",  
            },
                    // X轴
                    xAxis: {
                    data: arrs,
                    splitLine:{
                    show:false,
                },
                    },
                    // Y轴
                    yAxis: {  splitLine:{
                    show:false,
                },},
                   
                    // 数据
                    series: [
                        {
                            name: '七日新增',
                            type: 'bar',
                            
                            data:arrs1
                        },
                        {
                            name: '累计总数',
                            type: 'bar',  barGap:'-100%',
                            data:arrs2
                        }
                      
                    ]
                };

                myChartDrawer.setOption(option);
           
        })
        .catch(function(){
           
        })
    },
    seven1(){
           var that  = this
            var date=new Date();
    //近七天
    let timer = that.timeForMat(-1)
    let timer2 = that.timeForMat(6)
         var dateStart1 = date.getFullYear() + '-' +(date.getMonth() + 1) + '-' + date.getDate()
              that.$axios.get("/s/backend/index/order_statistic", {
        　  　params: { 'accessToken':localStorage.getItem('token'),
        'starttime':timer2,
        'endtime':timer}
        })

        .then(res=>{
                that.c_list1=  res.data.result
            let arrs = []
            let arrs1 = []
           let arrs2 = []
            that.c_list1.statistic.forEach(e => {
            arrs.push(e.province)
             arrs1.push(e.today)
            arrs2.push(e.total)
         })
         console.log(arrs1,arrs2)
     
                  let myChartDrawer =  this.$echarts.init(document.getElementById('myChart01'))
       
                // 绘制条形图
                var option = {
                    title: {
                        text: 'TOP10城市统计',
                        top: 5,
                        left: 'left'
                    },
                       color:['#4C73FD','#0034FF'],
                    legend: {
                        data: ['七日新增', '累计总数'],
                        top: 30
                    },  tooltip: {
                 trigger: "axis",  
            },
                    // X轴
                    xAxis: {
                    data: arrs,
                    splitLine:{
                    show:false,
                },
                    },
                    // Y轴
                    yAxis: {  splitLine:{
                    show:false,
                },},
                   
                    // 数据
                    series: [
                        {
                            name: '七日新增',
                            type: 'bar',
                            
                            data:arrs1
                        },
                        {
                            name: '累计总数',
                            type: 'bar',  barGap:'-100%',
                            data:arrs2
                        }
                      
                    ]
                };

                myChartDrawer.setOption(option);
           
        })
        .catch(function(){
           
        })
    },
        
    },
    mounted(){

     this.$axios.get("/s/backend/message/get_message_unread", {
　  　params: { 'accessToken':localStorage.getItem('token')}
})
  .then(res=>{
         this.result = res.data.result
          
        })
        .catch(function(){
           
        })  


  this.$axios.get("/s/backend/api/jszj_bussiness_statistic", {
　  　params: { 'accessToken':localStorage.getItem('token')}
})
  .then(res=>{
          that.lists=res.data.results
   
        })
        .catch(function(){
          
        })
  

       this.$axios.get("/s/backend/api/user_detail", {
        params: { 'accessToken':localStorage.getItem('token'),
        'jobnumber':localStorage.getItem('jobnumber')
        }
        })
     .then(res=>{
          that.info = res.data.data
     
        })
        .catch(function(){
           
        })


         this.$axios.get("/s/backend/index/top_six_tls_statistic", {
　  　params: { 'accessToken':localStorage.getItem('token')}
})
  .then(res=>{
         
            this.tableData = res.data.result
        })
        .catch(function(){
           
        })
  

    

 this.$axios.get("/s/backend/index/top_six_order_statistic", {
　  　params: { 'accessToken':localStorage.getItem('token')}
})
  .then(res=>{
         
            that.tableData1 = res.data.result
        })
        .catch(function(){
           
        })



        var that = this
    //近七天
    let timer = that.timeForMat(182)
    let timer2 = that.timeForMat(7)


      
      //本月
      var date=new Date();
      date.setDate(1);
     var dateStart = date.getFullYear() + '-' + (date.getMonth() + 1) + '-' +  date.setDate(1);
      var  timer3 =dateStart
 
  
  //一年
          var date=new Date();
           var timer4= date.getFullYear()+'-'+ '01' + "-"+'01' +' ' + '00:00:00' 

    var dateStart1 = date.getFullYear() + '-' +(date.getMonth() + 1) + '-' + date.getDate()


   
 
      that.$axios.get("/s/backend/index/banner", {
　  　params: { 'accessToken':localStorage.getItem('token')}
})

        .then(res=>{
                that.tls_count=  res.data.result.tls_count
      
        })
        .catch(function(){
           
        })

   let timer123 = that.timeForMat(0)
  let timer124 = that.timeForMat(-1)
          that.$axios.get("/s/backend/index/new_tls_statistic", {
        　  　params: { 'accessToken':localStorage.getItem('token'),
        'starttime':timer123,
        'endtime':timer124}
        })

        .then(res=>{
                that.c_list=  res.data.result
            let arrs = []
            let arrs1 = []
           let arrs2 = []
            that.c_list.statistic.forEach(e => {
            arrs.push(e.name)
             arrs1.push(e.today)
            arrs2.push(e.total)
         })
         console.log(arrs1,arrs2)
     
                  let myChartDrawer =  this.$echarts.init(document.getElementById('myChart'))
       
                // 绘制条形图
                var option = {
                    title: {
                        text: 'TOP10城市统计',
                        top: 5,
                        left: 'left'
                    },
                       color:['#4C73FD','#0034FF'],
                    legend: {
                        data: ['今日新增', '累计总数'],
                        top: 30
                    },  tooltip: {
                 trigger: "axis",  
            },
                    // X轴
                    xAxis: {
                    data: arrs,
                    splitLine:{
                    show:false,
                },
                    },
                    // Y轴
                    yAxis: {  splitLine:{
                    show:false,
                },},
                   
                    // 数据
                    series: [
                        {
                            name: '今日新增',
                            type: 'bar',
                            
                            data:arrs1
                        },
                        {
                            name: '累计总数',
                            type: 'bar',  barGap:'-100%',
                            data:arrs2
                        }
                      
                    ]
                };

                myChartDrawer.setOption(option);
           
        })
        .catch(function(){
           
        })

        

   var t111= that.timeForMat(0)
  var t112 = that.timeForMat(-1)
          that.$axios.get("/s/backend/index/order_statistic", {
        　  　params: { 'accessToken':localStorage.getItem('token'),
        'starttime':t111,
        'endtime':t112}
        })

        .then(res=>{
                that.c_list1=  res.data.result
            let arrs = []
            let arrs1 = []
           let arrs2 = []
            that.c_list1.statistic.forEach(e => {
            arrs.push(e.province)
             arrs1.push(e.today)
            arrs2.push(e.total)
         })
      
     
                  let myChartDrawer =  this.$echarts.init(document.getElementById('myChart0'))
       
                // 绘制条形图
                var option = {
                    title: {
                        text: 'TOP10城市统计',
                        top: 5,
                        left: 'left'
                    },
                       color:['#4C73FD','#0034FF'],
                    legend: {
                        data: ['今日新增', '累计总数'],
                        top: 30
                    },  tooltip: {
                 trigger: "axis",  
            },
                    // X轴
                    xAxis: {
                    data: arrs,
                    splitLine:{
                    show:false,
                },
                    },
                    // Y轴
                    yAxis: {  splitLine:{
                    show:false,
                },},
                   
                    // 数据
                    series: [
                        {
                            name: '今日新增',
                            type: 'bar',
                            
                            data:arrs1
                        },
                        {
                            name: '累计总数',
                            type: 'bar',  barGap:'-100%',
                            data:arrs2
                        }
                      
                    ]
                };

                myChartDrawer.setOption(option);
           
        })
        .catch(function(){
           
        })
     
      
     
    }
}
</script>

<style scoped>

/deep/.el-dialog__wrapper{
    top:-50px
}

/deep/ .el-dialog{
        width: 25%;

    float: right;
  
}






.item li:hover{
    color: #0034FF;
}
.item li a:hover{
    color: #0034FF !important;
}
   /deep/.el-table th > .cell {
      text-align: center;

    }

    /deep/.el-table .cell {
      text-align: center;

    }

/deep/.el-tabs--card>.el-tabs__header{
    border-bottom: none !important;
}

/deep/ .el-tabs--card>.el-tabs__header .el-tabs__item{
    border-bottom:1px solid #E4E7ED;}
/deep/ .el-tabs__item.is-active{
    border:1px solid #409EFF;
    border-left:1px solid #409EFF !important;
      border-bottom:1px solid #409EFF !important;
}
    .header{

min-height: 57px;        display: flex;
        align-items: center;
        width: 100%;
        justify-content: space-between;
    }
    .header .left_menu{
        display: flex;   width: 100%;  padding:0 40px;
        justify-content: space-between;
    }
    .header .left_menu .item{
        display: flex;
        align-items: center;
    }
        .header .left_menu .item .a{
            margin-right: 60px;  
        }
           .header .left_menu .item li a{
               text-decoration: none;color: #000;font-size: 16px;
font-weight: 500;
           }

        .header .active{
            color: #0034FF;
            position:relative;
        }
             .header .active::before{
                      content: "";
   
         left: 0;
    position: absolute;
    bottom:-120%;
    height:3px;
    width:30px;
    background: #0034FF;
            }
        .content{
            background: #F5F5F5;padding:10px 40px;
        }
        .content .content_ul{
            display: flex;
          margin:20px 0
        }
         .content .content_ul li{
             width:20%;padding-left: 15px;
         }
         .content_1{
             display: flex;
         }
         .content_1 .left,.right{
             width: 47%;
         }
</style>